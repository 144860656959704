import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87')
];

export const server_loads = [0,5,6,8,9,4,3];

export const dictionary = {
		"/(app)": [13,[2]],
		"/(app)/+[usernameOrId=username_or_id]": [~22,[2]],
		"/(app)/+[usernameOrId=username_or_id]/og.png/preview": [~23,[2]],
		"/(app)/@[org=org_code]": [~24,[2,5]],
		"/(app)/@[org=org_code]/(members)/billing": [~25,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/contests": [26,[2,5,6]],
		"/(app)/@[org=org_code]/join": [34,[2,5]],
		"/(app)/@[org=org_code]/join/check-email": [35,[2,5]],
		"/(app)/@[org=org_code]/join/with-email": [~36,[2,5]],
		"/(app)/@[org=org_code]/(members)/leave": [27,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/members": [~28,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/notifications": [29,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/sessions": [30,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/settings": [31,[2,5,6]],
		"/(app)/@[org=org_code]/(members)/teams": [32,[2,5,6]],
		"/(app)/@[org=org_code]/[code=session_code]": [~33,[2,5]],
		"/_email/manage-topics": [~86,[12]],
		"/_email/unsubscribe": [~87,[12]],
		"/(app)/admin": [37,[2]],
		"/(app)/admin/booth": [38,[2]],
		"/(app)/admin/colors": [39,[2]],
		"/(app)/admin/components": [40,[2]],
		"/(app)/admin/exercises": [41,[2]],
		"/(app)/admin/posts": [42,[2,7]],
		"/(app)/admin/posts/[postId=uid]": [~43,[2,7]],
		"/(app)/admin/render": [44,[2]],
		"/(app)/admin/robot": [45,[2]],
		"/(app)/admin/stats": [~46,[2]],
		"/(app)/admin/stripe": [~47,[2]],
		"/(app)/blog": [~48,[2]],
		"/(app)/blog/[slug]": [49,[2,8]],
		"/(app)/blog/[slug]/og.png/preview": [50,[2,8]],
		"/(app)/business": [51,[2]],
		"/(app)/contact": [52,[2]],
		"/(app)/contests/[contestId=integer]": [53,[2]],
		"/(app)/help": [~54,[2,9]],
		"/(app)/help/[...slugs]": [~55,[2,9]],
		"/(app)/internal/exercise": [56,[2]],
		"/(app)/legal/legal-notice": [57,[2,10]],
		"/(app)/legal/privacy-policy": [58,[2,10]],
		"/(app)/legal/terms-of-service": [59,[2,10]],
		"/(app)/(auth)/(signed-out)/login": [15,[2,4]],
		"/(app)/(auth)/logout": [18,[2]],
		"/(app)/ms-teams-tab": [60,[2]],
		"/(app)/pomodoro": [~61,[2]],
		"/(app)/pricing": [~62,[2]],
		"/(app)/(auth)/(signed-out)/register": [16,[2,4]],
		"/(app)/(auth)/register/finish": [19,[2]],
		"/(app)/(auth)/(signed-out)/reset-password": [17,[2,4]],
		"/(app)/(auth)/reset-password/set": [20,[2]],
		"/(app)/session/create": [65,[2]],
		"/(app)/session/embeddable": [66],
		"/(app)/session/[sessionUid=session_uid]": [~63,[2]],
		"/(app)/session/[sessionUid=session_uid]/join": [64,[2]],
		"/(app)/settings": [67,[2,11]],
		"/(app)/settings/account": [68,[2,11]],
		"/(app)/settings/billing": [~69,[2,11]],
		"/(app)/settings/contests": [70,[2,11]],
		"/(app)/settings/contests/new": [73,[2,11]],
		"/(app)/settings/contests/[contestId=integer]": [71,[2,11]],
		"/(app)/settings/contests/[contestId=integer]/edit": [72,[2,11]],
		"/(app)/settings/friends": [74,[2,11]],
		"/(app)/settings/friends/requests": [~75,[2,11]],
		"/(app)/settings/notifications": [76,[2,11]],
		"/(app)/settings/notifications/link-ms": [77,[2,11]],
		"/(app)/settings/notifications/link-slack": [78,[2,11]],
		"/(app)/settings/organizations": [79,[2,11]],
		"/(app)/settings/organizations/add-slack-workspace": [80,[2,11]],
		"/(app)/settings/organizations/add-slack-workspace/success": [81,[2,11]],
		"/(app)/settings/organizations/link-slack": [~82,[2,11]],
		"/(app)/settings/organizations/link-team": [~83,[2,11]],
		"/(app)/settings/organizations/new": [84,[2,11]],
		"/(app)/settings/profile": [85,[2,11]],
		"/(app)/(auth)/verify-email": [21,[2]],
		"/(app)/(auth)/(signed-in)/welcome": [14,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';